import React from "react";

import "./Gallery.css";
import { Heading } from "../Heading/Heading";

import LazyLoad from "react-lazyload";

const GalleryImage = ({ data, height, index }) => {
  const [underlayerVisible, setUnderlayerVisible] = React.useState(false);

  return (
    <div
      style={{
        marginTop: `${Math.random() * 2 - 1}rem`,
        marginLeft: `${Math.random() * 2 - 1}rem`,
        marginBottom: `${Math.random() * 2 - 1}rem`,
        marginRight: `${Math.random() * 2 - 1}rem`,
      }}
    >
      <div
        style={{
          position: "relative",
        }}
        onMouseEnter={() => setUnderlayerVisible(true)}
        onMouseLeave={() => setUnderlayerVisible(false)}
      >
        <LazyLoad height={height} once>
          {underlayerVisible && data.underlayer ? (
            <img alt="hidden" src={data.underlayer} />
          ) : (
            <img alt="visible" src={data.image} />
          )}
        </LazyLoad>
        <span
          style={{
            position: "absolute",
            top: "-8px",
            left: "-8px",
            paddingLeft: Math.random() * 16 - 8,
            paddingRight: Math.random() * 16 - 8,
            paddingTop: Math.random() * 16 - 8,
            paddingBottom: Math.random() * 16 - 8,
            transform: `rotate(${Math.random() * 20 - 10}deg)`,
            background: "var(--text)",
            color: "var(--bg)",
            whiteSpace: "nowrap",
          }}
        >
          <Heading
            period={4}
            start={(index * 5) % 8}
            tag="h3"
            text={data.caption}
          />
        </span>
      </div>
    </div>
  );
};

export const Gallery = (props) => {
  return (
    <div
      className={`gallery grid-${props.grid || 3} grid-start-${
        props.gridStart || "auto"
      } grid-end-${props.gridEnd || "auto"}`}
      style={{
        gap: props.gutter || 16,
      }}
    >
      {props.data.map((data, index) => {
        return <GalleryImage data={data} height={props.height} index={index} />;
      })}
    </div>
  );
};

import { Link } from "react-router-dom";

import { Heading } from "../Heading/Heading";
import "./NavLink.css";

export const NavLink = (props) => {
  return (
    <div
      className={`nav-link align-${props.verticalAlign} grid-start-${
        props.gridStart || "auto"
      } grid-end-${props.gridEnd || "auto"} grid-row-start-${
        props.gridRowStart || "auto"
      } grid-row-end-${props.gridRowEnd || "auto"}`}
    >
      <Link to={props.to}>
        <Heading
          period={props.period}
          start={props.start}
          tag="span"
          text={props.text}
        />
      </Link>
    </div>
  );
};

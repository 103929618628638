import {
  DynamicImageParagraph,
  Heading,
  Layout,
  Paragraph,
} from "../../../components/utils";

import val from "../../../img/val.gif";
import nyc from "../../../img/nyc.jpg";
import twod from "../../../img/2d.jpg";
import threed from "../../../img/3d.jpg";
import paint from "../../../img/paint.jpg";
import ceramics from "../../../img/ceramics.jpg";
import digital from "../../../img/digital.jpg";
import nyu from "../../../img/nyu.jpg";

export const About = () => {
  return (
    <Layout grid={8}>
      <Heading
        period={6}
        start={0}
        gridStart={3}
        gridEnd={6}
        tag="h2"
        text="about me"
      />
      <DynamicImageParagraph
        textGridStart={1}
        textGridEnd={7}
        imageGridStart={4}
        imageGridEnd={9}
        height="50vh"
        defaultImage={val}
        data={[
          ["Valeria Sofia", val],
          [" is a visual artist based in "],
          ["New York City", nyc],
          [". A multidisciplinary artist, she works with both "],
          ["2D", twod],
          [" and "],
          ["3D", threed],
          [" media, including "],
          ["paint", paint],
          [", "],
          ["ceramics", ceramics],
          [", and "],
          ["digital", digital],
          [". She has a Bachelor of Fine Arts from "],
          ["New York University", nyu],
          ["."],
        ]}
      />
      <Heading
        period={7}
        start={3}
        gridStart={2}
        gridEnd={6}
        tag="h2"
        text="getting in touch"
      />
      <Paragraph gridStart={4} gridEnd={9}>
        <a href="mailto:valeriaso.fi">
          <Heading
            period={11}
            start={8}
            tag="span"
            text="email: hello@valeriaso.fi"
          />
        </a>
        <br />
        <a href="https://www.instagram.com/valeriasofia.art/">
          <Heading
            period={11}
            start={0}
            tag="span"
            text="insta: @valeriasofia.art"
          />
        </a>
      </Paragraph>
    </Layout>
  );
};

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import "./App.css";
import "./fonts/stylesheet.css";
import {
  About,
  Digital,
  MixedMedia,
  NotFound,
  Sculpture,
  TwoDimensional,
} from "./components/pages";
import { Nav } from "./components/utils";

const Inner = () => {
  const history = useHistory();

  const changeFavicon = () => {
    let favicon = document.getElementById("favicon");
    let currHref = favicon.getAttribute("href");
    console.log(currHref);
    let newHref = currHref.replace(
      /favicon.{1,3}\.png/,
      `favicon${Math.floor(Math.random() * 12 + 1)}.png`
    );
    console.log(newHref);
    document.getElementById("favicon").setAttribute("href", newHref);
  };

  React.useEffect(() => {
    changeFavicon();
    return history.listen(changeFavicon);
  }, [history]);

  return (
    <>
      <Nav />
      <Switch>
        <Route path="/digital" component={Digital} />
        <Route path="/mixed-media" component={MixedMedia} />
        <Route path="/sculpture" component={Sculpture} />
        <Route path="/2d" component={TwoDimensional} />
        <Route exact path="/" component={About} />
        <Route path="/" component={NotFound} />
      </Switch>
      <div
        style={{
          marginBottom: "25vh",
        }}
      ></div>
    </>
  );
};

const App = () => {
  return (
    <>
      <Router>
        <Inner />
      </Router>
    </>
  );
};

export default App;

import "./Paragraph.css";

export const Paragraph = (props) => {
  return (
    <div
      className={`grid-start-${props.gridStart || "auto"} grid-end-${
        props.gridEnd || "auto"
      }`}
    >
      {props.children}
    </div>
  );
};

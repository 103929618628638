import React from "react";

import { Heading } from "../Heading/Heading";
import { Layout } from "../Layout/Layout";
import { NavLink } from "../NavLink/NavLink";
import "./Nav.css";

export const Nav = () => {
  const [showName, setShowName] = React.useState(false);

  return (
    <nav>
      <Layout grid={2}>
        <div
          style={{
            position: "relative",
          }}
          className="grid-start-1 grid-end-2"
          onMouseEnter={() => setShowName(true)}
          onMouseLeave={() => setShowName(false)}
        >
          <Heading period={5} start={6.5} tag="h1" text="valeria" />
          {showName ? (
            <>
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "10ch",
                  pointerEvents: "none",
                }}
              >
                <Heading period={2.5} start={1} tag="h2" text="sofia" />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="link-grid">
          <NavLink
            period={4}
            start={5}
            gridStart={1}
            gridEnd={2}
            gridRowStart={1}
            gridRowEnd={2}
            verticalAlign="middle"
            to="/"
            text="home"
          />
          <NavLink
            period={6}
            start={5}
            gridStart={4}
            gridEnd={6}
            gridRowStart={1}
            gridRowEnd={2}
            verticalAlign="bottom"
            to="/digital"
            text="digital"
          />
          <NavLink
            period={3}
            start={1}
            gridStart={8}
            gridEnd={9}
            gridRowStart={1}
            gridRowEnd={2}
            verticalAlign="top"
            to="/2d"
            text="2d"
          />
          <NavLink
            period={8}
            start={4}
            gridStart={2}
            gridEnd={4}
            gridRowStart={2}
            gridRowEnd={3}
            verticalAlign="bottom"
            to="/sculpture"
            text="sculpture"
          />
          <NavLink
            period={3}
            start={1}
            gridStart={6}
            gridEnd={9}
            gridRowStart={2}
            gridRowEnd={3}
            verticalAlign="middle"
            to="/mixed-media"
            text="mixed media"
          />
        </div>
      </Layout>
    </nav>
  );
};

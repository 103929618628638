import "./Heading.css";

const fontCycle = 8;

const randomWithSeed = (seed) => {
  let x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
};

const Text = (props) => {
  const periodFactor = fontCycle / props.period;

  return Array.from(props.text).reduce((acc, curr, index) => {
    let font = props.random
      ? Math.floor(Math.random() * 3)
      : Math.floor(
          randomWithSeed(
            (index + 1) * props.text.length * props.text.charCodeAt(0)
          ) * 3
        );
    let wght =
      fontCycle -
      Math.abs(
        fontCycle -
          (Math.round((props.start + index) * periodFactor) % (2 * fontCycle))
      );

    return (
      <>
        {acc}
        <span className={`wght-${wght} font-${font}`}>{curr}</span>
      </>
    );
  }, <></>);
};

export const Heading = (props) => {
  const PropTag = props.tag || "h1";
  return (
    <PropTag
      className={`grid-start-${props.gridStart || "auto"} grid-end-${
        props.gridEnd || "auto"
      }`}
    >
      <Text
        period={props.period || 9}
        start={props.start || 0}
        text={props.text}
        random={typeof props.random === "undefined" ? true : props.random}
      />
    </PropTag>
  );
};

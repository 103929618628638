import React from "react";

import { Heading } from "../Heading/Heading";
import "./DynamicImageParagraph.css";

export const DynamicImageParagraph = (props) => {
  const [image, setImage] = React.useState(["", props.defaultImage]);

  return (
    <>
      <div
        className={`grid-start-${props.textGridStart || "auto"} grid-end-${
          props.textGridEnd || "auto"
        }`}
      >
        {props.data.reduce((acc, curr, index) => {
          return (
            <>
              {acc}
              {curr.length === 1 ? (
                <span>{curr[0]}</span>
              ) : (
                <span
                  className="trigger"
                  onMouseEnter={() => setImage(curr)}
                  onMouseLeave={() => setImage(["", props.defaultImage])}
                >
                  <Heading
                    text={curr[0]}
                    start={index}
                    random={false}
                    tag="span"
                  />
                </span>
              )}
            </>
          );
        }, <></>)}
      </div>
      <div
        className={`image__container grid-start-${
          props.imageGridStart || "auto"
        } grid-end-${props.imageGridEnd || "auto"}`}
        style={{
          height: props.height,
        }}
      >
        <img alt={image[0]} className="image" src={image[1]} />
      </div>
    </>
  );
};
